<template>
  <div class="page-container">
    <div class="title-container">
      <Title :title="title" />
      <div></div>
    </div>
    <div class="container">
      <Filters @on-filtered="onFilterChange" />
      <div class="flex">
        <div class="flex-none">
          <div class="secondary-side__menu">
            <Menu :items="menuLinks" :disabled="isFiltered" />
          </div>
        </div>
        <div class="flex-grow pl-4 sub-content__area">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  components: {
    Title: () => import(/* webpackPrefetch: true */ "../common/Title"),
    Menu: () => import(/* webpackPrefetch: true */ "../common/Menu"),
    Filters: () => import(/* webpackPrefetch: true */ "./Filters"),
  },
  data: () => ({
    title: "Formulation Mapping",
    isFiltered: true,
    menuLinks: [
      {
        name: "Formulation Brand - Product Mapping",
        path: "/formulation-mapping/productmapping",
      },
      {
        name: "Additional Information",
        path: "/formulation-mapping/additionalinfo",
      },
      {
        name: "Comparison",
        path: "/formulation-mapping/comparasion",
      },
    ],
  }),
  // created() {},
  methods: {
    // ...mapActions({
    //   updateIsfiltered: "fetchIsFiltered",
    //   // updateIsMenuOpen: "fetchIsMenuOpen",
    // }),
    ...mapMutations({ isMenuOpen: "SET_IS_MENU_OPEN", isfiltered:"SET_IS_FILTERED" }),
    onFilterChange(val) {
      this.isFiltered = !val;
      this.isfiltered(val);
      this.isMenuOpen(false);
      if (this.$route.name === "formulationmapping") {
        this.$router.push({ name: "productmapping" }).catch(() => {});
      } else {
        this.$router.push({ name: this.$route.name }).catch(() => {});
      }
    },
  },
};
</script>
